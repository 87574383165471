<template>
    <div>
        <navbar
        :items="navbarItems"
        :fonticon="pageIcon"
        :navbartitle="pageTitle"
        :navbardescription="pageDescription"
        />
      
      <data-table
        :data-source="sortedModules"
        :headers="headers"
        table-class="table-striped table-hover"
      >
        <template slot="actions">
          <a
            href="javascript:void(0)"
            class="btn btn-outline-primary btn-icon"
            @click.prevent="initForm"
            data-toggle="modal"
            data-target="#add-pack-module"
          >
            <i class="icofont icofont-plus" />
          </a>
        </template>
      </data-table>
      <modal
        title="Ajout de Module complémentaire au Packs"
        id="add-pack-module"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="addingModule"
        >
        <div class="row" v-if="has_error">
          <div class="alert alert-danger">{{ error_msg }}</div>
        </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="add-libelle">Libelle</label>
                    <input
                        type="text"
                        id="add-libelle"
                        name="add-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="add-services">Services</label>
                    <select v-select="{placeholder: 'Selectionnez les service du pack'}" class="form-control" name="add-services" id="add-services" v-model="services" multiple>
                        <option></option>
                        <option v-for="(s, index) in allServices" :key="index" :value="s.uid">{{ s.libelle }}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="update-amount">Prix</label>
                    <input
                        type="text"
                        id="update-amount"
                        name="update-amount"
                        v-model="amount"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
                <add-product-photo :index="0"/>
            </div>
            <div class="col-8">

            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Ajouter
              </button>
            </div>
          </div>
        </form>
      </modal>
      <modal
        title="Modification du module complémentaire"
        id="update-pack-module"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="editingPack"
        >
          <div class="row" v-if="has_error">
            <div class="alert alert-danger">{{ error_msg }}</div>
          </div>
          <div class="row">
            <div class="col-8">
                <div class="form-group">
                    <label for="edit-libelle">Libelle</label>
                    <input
                        type="text"
                        id="edit-libelle"
                        name="edit-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="update-services">Services</label>
                    <select v-select="{placeholder: 'Selectionnez les service du pack'}" class="form-control" name="update-services" id="update-services" v-model="services" multiple>
                        <option></option>
                        <option v-for="(s, index) in allServices" :key="index" :value="s.uid">{{ s.libelle }}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
                <add-product-photo :index="0"/>
            </div>
            <div class="col-8">

            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </modal>
    </div>
  </template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import DataTable from '../../../components/dataTable/local.vue'
  import {TEXT_TYPE, COMPONENT_TYPE, NUMERIC_TYPE } from '../../../components/dataTable/dataType'
  import Modal from '../../../components/modal.vue'
import Navbar from '../../../components/navbar.vue'
import { ADD_PACK_MODULE, UPDATE_PACK_MODULE } from '../../../graphql/pack'
import AddProductPhoto from '../../../components/market/addProductPhoto.vue'
  const Actions = () => import('../../../components/admin/pack/moduleActions.vue')
  const ModulePhoto = () => import ('../../../components/admin/pack/modulePhoto.vue')
  const Services = () => import ('../../../components/admin/pack/parckService.vue')
  export default {
      components: { Navbar, DataTable, Modal, AddProductPhoto},
      data(){
          return {
            navbarItems: [
              {
                libelle: 'Pack Managment'
              },{
                libelle: 'Modules complémentaires'
              }
            ],
            pageIcon: 'la-gift',
            pageTitle: 'Module managment',
            pageDescription: 'Gestion des module complémentaire d\'un pack d\'abonnement sur sur CASEAC',
            libelle: null,
            code: null,
            services: [],
            amount: 0,
            photo: null,
            has_error: false,
            error_msg: null
          }
      },
      watch: {
        selectedObject: {
              handler(){
                  if(this.selectedObject !== null) {
                    this.libelle = this.selectedObject.libelle
                    this.code = this.selectedObject.code
                    this.services = JSON.parse(this.selectedObject.services)
                    this.amount = this.selectedObject.amount
                    this.photo = JSON.parse(this.selectedObject.photo)
                    }
              },
              deep: true
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              setSelectedObject: 'SET_SELECTED_OBJECT',
              setPhotos: 'market/SET_PRODUCT_PHOTOS'
          }),
          initForm(){
            this.setSelectedObject(null)
              this.libelle = null
              this.code = null
              this.services = []
              this.amount = 0
              this.photo = null
              this.setPhotos([])
              
          },
          addingModule(){
              let data = {
                libelle: this.libelle,
                services: JSON.stringify(this.services),
                amount: parseFloat(this.amount),
                photo: this.photos.length > 0 ? JSON.stringify(this.photos[0]) : null
              }
              this.$apollo.mutate({
                  mutation: ADD_PACK_MODULE,
                  variables: {
                      "module": {
                          ...data
                      }
                  },
                  update: ({data}) => {
                      console.log(`Pack module add successfully on uid ${data.addMarketTypeObject}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editingPack(){
              let data = {
                libelle: this.libelle,
                  description: this.description,
                  services: JSON.stringify(this.services),
                  amount: parseFloat(this.amount),
                  agent : this.agent,
                  photo: this.photos.length > 0 ? JSON.stringify(this.photos[0]) : null
              }
              this.$apollo.mutate({
                  mutation: UPDATE_PACK_MODULE,
                  variables: {
                      "module": {
                          ...data
                      }, 
                      "uid": this.selectedObject.uid
                  },
                  update: () => {
                      console.log(`Pack Module ${this.selectedObject.libelle} edit successfully on uid ${this.selectedObject.uid}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
      },
      computed: {
          ...mapGetters({
              modules: 'packs/modules',
              allServices: 'packs/services',
              selectedObject: 'selectedObject',
              photos: 'market/productPhotos'
          }),
          sortedModules(){
            return [...this.modules].sort((a,b) => a.libelle.localeCompare(b.libelle))
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Photo', name: 'photo', type: COMPONENT_TYPE, component: ModulePhoto},
                  {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                  {label: 'Code', name: 'code', type: TEXT_TYPE},
                  {label: 'Services', name: 'services', type: COMPONENT_TYPE, component: Services},
                  {label: 'Prix (fCFA)', name: 'amount', type: NUMERIC_TYPE},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>